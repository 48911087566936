import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const Verb = () => {
  const data = useStaticQuery(graphql`
  query coaxVerb {
    file(relativePath: {eq: "logos/coaxDesigns/coaxDesigns_Verb White.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)
return <Img fluid={data.file.childImageSharp.fluid} />
}

export default Verb