import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { readBuilderProgram } from "typescript"

import "./header.css"

import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import Verb from "./coax-verb-white"

const Icon = () => {
  const data = useStaticQuery(graphql`
  query coaxIcon {
    file(relativePath: {eq: "logos/coaxDesigns/coaxDesigns_Icon White.png"}) {
      childImageSharp {
        fixed(width: 60) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`)
return <Img fixed={data.file.childImageSharp.fixed} />
}

const Header = ({ siteTitle }) => (
  <header className="nav">
    <div className="navbar">
      <div className="brand">
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          <Icon/>
        </Link>
      </div>
      <div className="search">
        <Verb/>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
